// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-legal-notice-js": () => import("/opt/build/repo/src/pages/legal/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-legal-notice-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("/opt/build/repo/src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("/opt/build/repo/src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */)
}

